import React, { useLayoutEffect, useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { get } from "../utils/get.js";

const IndexPage = () => {
  //let uId = undefined;
  const [uId, setUId] = useState(undefined);
  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      // Collect User ID from URL parameter.
      const url_string = window.location.href;
      const url = new URL(url_string);
      setUId(url.searchParams.get("u"));
    }
  }, []);
  useEffect(() => {
    if(uId !== undefined) {
      get(`static/log/LogEvent.php?u=${uId}&e=open`);
    }
  }, [uId]);
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Gothic+A1&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Lato&display=swap"
        rel="stylesheet"
      />
      {uId && <Layout uId={uId} />}
    </>
  );
};

export default IndexPage;
